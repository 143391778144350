var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-form-input", {
    directives: [
      {
        name: "mask",
        rawName: "v-mask",
        value: "###-##-####",
        expression: "'###-##-####'",
      },
    ],
    attrs: { type: "text" },
    on: { input: _vm.validateAndEmitSSN },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }